import React, { useState, useEffect } from 'react';
import COLORS from "../../resources/colors"

export default function Good(props) { 

    function buttonTapped() { 
        if (props.displayForm != null) { 
            props.displayForm()
        }
    }

    return(
        <div id="statsgood">
            <div className="Content">
                <h1 className="Title">Good for employees<br></br><span style={{color: COLORS.myClerkieBlack}}>Great for employers.</span></h1>
                <h1 className="Paragraph">Reduce employee financial stress and increase retention<br></br>and productivity in your business.</h1>
                <div className="ButtonContainer">
                   <button className="Button" onClick={buttonTapped}>Request Demo</button>
                </div>
                
            </div>
            <style jsx="true">{`

                #statsgood .Content { 
                    font-family: "Helvetica Neue", "Arial";
                    width: 100%;
                    background-color: ${COLORS.black};
                    padding: 5.95vw 0px;
                }

                #statsgood .Title { 
                    width: 80%;
                    font-weight: 800;
                    font-size: 3.27vw; 
                    line-height: 4vw;
                    text-align: center;
                    text-transform: uppercase;
                    margin: 0px auto 0px auto;
                    color: ${COLORS.funPurple};
                    padding: 0px 0px 0px 0px;
                }

                #statsgood .Paragraph { 
                    width: 50vw;
                    font-weight: 800;
                    font-size: 1.42vw; 
                    text-align: center;
                    line-height: 2.5vw;
                    margin: 0px auto 0px auto;
                    color: ${COLORS.myKeyboardGrayColor};
                    padding: 1.48vw 0px 1.48vw 0px;
                }

                #statsgood .ButtonContainer { 
                    display: grid;
                    align-items: center;
                }

                #statsgood .Button { 
                    justify-self: center;
                    font-size: 0.95vw;
                    font-weight: bold;
                    background-color: ${COLORS.funPurple};
                    color: ${COLORS.myClerkieBlack}; 
                    padding: 1.3vw 2.97vw;
                    border: 0px solid rgba(255, 255, 255, 1.0);
                    border-radius: 2.38vw;
                    cursor: pointer; 
                    color: white;
                }

                #statsgood button:focus { 
                    outline: none;
                }

                @media (max-width: 640px) { 
                    #statsgood .Content { 
                        font-family: "Helvetica Neue", "Arial";
                        width: 100%;
                        padding: 12vw 0px;
                    }
    
                    #statsgood .Title { 
                        width: 100vw;
                        font-weight: 800;
                        font-size: 6.5vw; 
                        line-height: 8.5vw;
                        text-align: center;
                        text-transform: uppercase;
                        margin: 0px auto 0px auto;
                        padding: 0px 0px 0px 0px;
                    }
    
                    #statsgood .Paragraph { 
                        width: 80vw;
                        font-weight: 800;
                        font-size: 2.84vw; 
                        text-align: center;
                        line-height: 4vw;
                        margin: 0px auto 0px auto;
                        padding: 3vw 0px 3vw 0px;
                    }
    
                    #statsgood .ButtonContainer { 
                        display: grid;
                        align-items: center;
                    }
    
                    #statsgood .Button { 
                        justify-self: center;
                        font-size: 2.25vw;
                        font-weight: bold;
                        padding: 3vw 7vw;
                        border: 0px solid rgba(255, 255, 255, 1.0);
                        border-radius: 6vw;
                        cursor: pointer; 
                        text-transform: none;
                    }
                }

            `}</style>
        </div>
    )
}   