import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import COLORS from "../../resources/colors"


export default class PayingDown extends React.Component { 
    
    constructor(props) { 
        super(props)

        this.myRef = React.createRef();

        this.state = { 
            height: 0,
            visible: false
        }    
    }

    componentDidMount() { 

        var height = 0
        
        if (this.myRef.current != null) { 
            height = this.myRef.current.clientHeight ? this.myRef.current.clientHeight : 0
        }

        this.setState({
            height: height
        })
    }

    visibilityChanged = (visible) => { 
        this.setState(
            {
                visible: visible
            }
        )
    }

    buttonTapped = () => { 
        if (this.props.displayForm != null) { 
            this.props.displayForm()
        }
    }

    render() { 
        return(
            <div id="statspayingdown">
                <div className="Content">
                    <div className="LeftContent">
    
                        <div/>
                        <div className="ProfileContainer">
                            <img alt="" className="ProfilePhoto" src={require('../../images/profile-image-5.jpeg')}/>
                            <div className="ProfileNameContainer">
                                <img src={require('../../images/profile-down-arrow.png')}></img>
                                <h1 className="ProfileName">$14,278</h1>
                            </div>
                        </div>
                        <div className="ProfileContainer">
                            <img alt="" className="ProfilePhoto" src={require('../../images/alex.jpg')}/>
                            <div className="ProfileNameContainer">
                                <img src={require('../../images/profile-down-arrow.png')}></img>
                                <h1 className="ProfileName">$2,343</h1>
                            </div>
                        </div>
                        <div className="ProfileContainer">
                            <img alt="" className="ProfilePhoto" src={require('../../images/profile-image-6.jpeg')}/>
                            <div className="ProfileNameContainer">
                                <img src={require('../../images/profile-down-arrow.png')}></img>
                                <h1 className="ProfileName">$22,389</h1>
                            </div>
                        </div>
                        <div className="ProfileContainer">
                            <img alt="" className="ProfilePhoto" src={require('../../images/melinda.jpg')}/>
                            <div className="ProfileNameContainer">
                                <img src={require('../../images/profile-down-arrow.png')}></img>
                                <h1 className="ProfileName">$1,220</h1>
                            </div>
                        </div>
             
                        
                    </div>
                    <div ref={this.myRef} className="RightContent">
                    <VisibilitySensor key="statspayingdown" active={this.state.visible == false} partialVisibility={true} minTopValue={this.state.height/2} onChange={this.visibilityChanged}>
                        <div className={this.state.visible ? "TextContent Visible" : "TextContent"}>
                            <h1 className="Title">Paying off debt has never been easier</h1>
                            <h1 className="Paragraph">Our plans make it easy for members to make the right money moves and take the first step toward financial health</h1>
                            <button className="Button" onClick={this.buttonTapped}>Learn More</button>
                        </div>
                    </VisibilitySensor>
                        
                    </div>
                </div>
                <style jsx="true">{`
    
                    #statspayingdown .Content { 
                        font-family: "Helvetica Neue", "Arial";
                        width: 100%;
                        display: grid;
                        grid-template-columns: 12.5vw 35vw 25vw 12.5vw; 
                        column-gap: 5vw;
                        grid-template-rows: 1fr;
                        align-items: start; 
                        padding: 5.95vw 0px;
                        background: black;
                    }
    
                    #statspayingdown .LeftContent { 
                        width: 100%;
                        height: auto;
                        grid-row: 1;
                        grid-column: 2;
                        padding: 0px 0px 0px 0px; 
                        display: grid;
                        grid-template-columns: 1fr 7.14vw 7.14vw 7.14vw 7.14vw;
                        column-gap: 1.25vw;
                    }
    
                    #statspayingdown .RightContent { 
                        width: 100%;
                        height: auto;
                        grid-row: 1;
                        grid-column: 3;
                        padding: 0px 0px 0px 0px;
                    }

                    #statspayingdown .TextContent { 
                        visibility: hidden;
                        opacity: 0;
                    }

                    @keyframes fadeIn {
                        0% {
                          visibility: hidden;
                          opacity: 0;
                        }
                        100% {
                          visibility: visible;
                          opacity: 1;
                        }
                    }

                    #statspayingdown .TextContent.Visible { 
                        animation-duration: 1s;
                        animation-name: fadeIn; 
                        animation-fill-mode: both;
                        animation-delay: 0.0s; 
                    }
    
                    #statspayingdown .Title { 
                        font-weight: 600;
                        font-size: 2.4vw; 
                        margin: 0px;
                        color: ${COLORS.white};
                        padding: 0px 0px 0px 0px;  
                    }
    
                    #statspayingdown .Paragraph { 
                        font-weight: 400;
                        font-size: 1.3vw;
                        line-height: 1.8vw;
                        margin: 0px;
                        padding: 1.19vw 0px;
                        color: ${COLORS.white};
                        opacity: 0.8;
                    }
    
                    #statspayingdown .Button { 
                        justify-self: center;
                        font-size: 1.0vw;
                        font-weight: bold;
                        background-color: ${COLORS.funPurple};
                        color: ${COLORS.white}; 
                        padding: 1.19vw 2.9vw;
                        border: 0px solid rgba(255, 255, 255, 1.0);
                        border-radius: 2.3vw;
                        cursor: pointer; 
                        text-transform: none;
                    }
    
                    #statspayingdown button:focus { 
                        outline: none;
                    }
    
                    #statspayingdown .ProfileContainer { 
                        display: grid;
                        width: 100%;
                    }
    
                    #statspayingdown .ProfilePhoto { 
                        justify-self: center;
                        grid-row: 1;
                        height: 7.14vw;
                        width: 7.14vw;
                        border-radius: 3.57vw;
                        padding: 0px;
                        margin: 0px;
                    }

                    #statspayingdown .ProfileNameContainer { 
                        justify-self: center;
                        display: flex;
                        grid-row: 2;
                        height: 1.4vw;
                        padding: 0.59vw 0px 0px 0px;
                        margin: 0px;
                    }

                    #statspayingdown .ProfileNameContainer img { 
                        object-fit: contain;
                        width: 1vw;
                        padding-right: 0.5vw;
                    }
    
                    #statspayingdown .ProfileName { 
                        justify-self: center;
                        font-size: 1.19vw;
                        font-weight: 600;
                        color: ${COLORS.myKeyboardGrayColor};
                        margin: 0px;
                    }

                    @media (max-width: 640px) { 
                        #statspayingdown .Content { 
                            font-family: "Helvetica Neue", "Arial";
                            width: 100%;
                            display: grid;
                            grid-template-columns: 1fr; 
                            column-gap: 0vw;
                            grid-template-rows: 1fr;
                            align-items: start; 
                            padding: 12vw 0px;
                        }
        
                        #statspayingdown .LeftContent { 
                            width: 100%;
                            height: auto;
                            grid-row: 1;
                            grid-column: 1;
                            padding: 0px 0px 0px 0px; 
                            display: grid;
                            grid-template-columns: 1fr 14.28vw 14.28vw 14.28vw 14.28vw 1fr;
                            grid-template-rows: 1fr;
                            column-gap: 4vw;
                        }
        
                        #statspayingdown .RightContent { 
                            width: 100%;
                            height: auto;
                            grid-row: 2;
                            grid-column: 1;
                            padding: 8vw 0px 0px 0px;
                        }

                        #statspayingdown .TextContent { 
                            width: 100%;
                            height: auto;
                            display: grid;
                            grid-template-rows: 1fr;
                        }

                        #statspayingdown .Title { 
                            width: 70vw;
                            font-weight: 600;
                            font-size: 4.8vw; 
                            margin: 0px;
                            padding: 0px 0px 0px 0px;  
                            text-align: center;
                            grid-column: 1;
                            grid-row: 1;
                            justify-self: center;
                        }
        
                        #statspayingdown .Paragraph { 
                            width: 70vw;
                            font-weight: 400;
                            font-size: 2.6vw;
                            text-align: center;
                            line-height: 3.6vw;
                            margin: 0px;
                            padding: 2.38vw 0px;
                            grid-column: 1;
                            grid-row: 2;
                            justify-self: center;
                        }
        
                        #statspayingdown .Button { 
                            justify-self: center;
                            font-size: 2.25vw;
                            font-weight: bold;
                            padding: 3vw 7vw;
                            border: 0px solid rgba(255, 255, 255, 1.0);
                            border-radius: 6vw;
                            cursor: pointer; 
                            text-transform: none;
                        }
        
                        #statspayingdown .ProfileContainer { 
                            display: grid;
                            width: 100%;
                        }
        
                        #statspayingdown .ProfilePhoto { 
                            justify-self: center;
                            grid-row: 1;
                            height: 14.28vw;
                            width: 14.28vw;
                            border-radius: 7.14vw;
                            padding: 0px;
                            margin: 0px;
                        }

                        #statspayingdown .ProfileNameContainer { 
                            justify-self: center;
                            display: flex;
                            grid-row: 2;
                            height: 2.8vw;
                            padding: 1.18vw 0px 0px 0px;
                            margin: 0px;
                        }
    
                        #statspayingdown .ProfileNameContainer img { 
                            object-fit: contain;
                            width: 2vw;
                            padding-right: 1vw;
                        }
        
                        #statspayingdown .ProfileName { 
                            justify-self: center;
                            font-size: 2.38vw;
                            font-weight: 600;
                            margin: 0px;
                        }
                    }
    
                `}</style>
            </div>
        )
    }

    
}   