import React, { useState, useEffect } from 'react';
import { Parallax } from 'react-scroll-parallax';
import COLORS from "../../resources/colors"




export default class Hero extends React.Component { 

    constructor(props) { 
        super(props);

        this.state = { 
          isMobile: false
        } 
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    handleResize = () => {

        const innerWidth = window.innerWidth;
        const isMobile = window.innerWidth <= 640;
        
        if (this.state.isMobile != isMobile) { 
            this.setState({ isMobile: isMobile } );
        }
      };

      

    buttonTapped = () => { 
        if (this.props.displayForm != null) { 
            this.props.displayForm()
        }
    }

    render() { 
        return(
            <div id="statshero">
                <div className="Content">
                    <div className="LeftContent">
                        <h1 className="Title">Help your community<br></br><span style={{fontWeight: 800}}>pay off their debt</span></h1>
                        <h1 className="Paragraph">Clerkie partners with employers, financial institutions, and unions to provide borrowers with personalized, data-driven repayment options.</h1>
                        <button className="Button" onClick={this.buttonTapped}>Let's work together</button>
                    </div>
                    <div className="RightContent">
                    <Parallax  y={[10, -5]} styleOuter={
                        this.state.isMobile ? {
                            height: "98.43vw",
                            width: "90vw",
                            gridRow: 1, 
                            gridColumn: 1,
                            overflow: "hidden",
                            zIndex: 1
                        } : {
                            height: "35vw",
                            width: "32vw",
                            gridRow: 1, 
                            gridColumn: 1,
                            overflow: "hidden",
                            zIndex: 1
                        }   
                    } >
                        <img className="HeroImage" src="https://clerkie-assets.s3-us-west-2.amazonaws.com/app-assets/save_split_goals/focus_group.jpg"></img>   
                    </Parallax>
                    <Parallax y={[-10, 15]} x={[10, 10]} styleOuter={
                        this.state.isMobile ? {
                            gridRow: 1, 
                            gridColumn: 1,
                            zIndex: 0,
                            transform: "scale(1.15, 1.15)"
                        } : 
                        {
                            gridRow: 1, 
                            gridColumn: 1,
                            zIndex: 0,
                            transform: "scale(1.25, 1.25)"
                        }
                    }>
                        <img src={require("../../images/parallelogram-web-light-blue.png")}></img>         
                    </Parallax>
                    
                        
                    </div>
                </div>
                <style jsx="true">{`
    
                    #statshero .Content { 
                        font-family: "Helvetica Neue", "Arial";
                        width: 100%;
                        height: 35vw;
                        display: grid;
                        grid-template-columns: 12.5vw 35vw 1fr 12.5vw; 
                        grid-template-rows: 1fr;
                        align-items: center; 
                        padding: 10vw 0px;  
                        margin: 0vw 0px 0px 0px; 
                    }
    
                    #statshero .LeftContent { 
                        padding: 0px 0px 0px 0px;
                        grid-column: 2;
                        grid-row: 1;
                    }
    
                    #statshero .RightContent { 
                        padding: 0px;
                        grid-column: 3;
                        grid-row: 1;
                        height: 35vw;
                        width: 32vw;
                        justify-self: end;
                        display: grid;
                    }

                    @keyframes fadeIn {
                        0% {
                          visibility: hidden;
                          opacity: 0;
                        }
                        100% {
                          visibility: visible;
                          opacity: 1;
                        }
                    }

                    #statshero .HeroImage { 
                        animation-duration: 1s;
                        animation-name: fadeIn; 
                        animation-fill-mode: both;
                        animation-delay: 0.0s; 
                    }
    
                    #statshero .Image { 
                        height: 100%;
                        width: 100%;
                    }
    
    
                    #statshero img { 
                        width: 100%;
                        object-fit: cover;
                        
                    }
    

                    #statshero .Title { 
                        font-weight: 500;
                        font-size: 2.9vw; 
                        margin: 0px;
                        color: ${COLORS.white};
                        padding: 0px 0px 0px 0px;
                    }
    
                    #statshero .Paragraph { 
                        font-weight: 400;
                        font-size: 1.19vw;
                        line-height: 1.78vw;
                        margin: 0px;
                        padding: 1.78vw 0px;
                        opacity: 0.8;
                        color: ${COLORS.white};
                    }
    
                    #statshero .Button { 
                        font-size: 0.95vw;
                        font-weight: bold;
                        background-color: ${COLORS.funPurple};
                        color: ${COLORS.myClerkieBlack}; 
                        padding: 1.19vw 2.97vw;
                        border: 0px solid rgba(255, 255, 255, 1.0);
                        border-radius: 2.38vw;
                        cursor: pointer; 
                        color: white;
                    }
    
                    #statshero button:focus { 
                        outline: none;
                    }
    
                    @media (max-width: 640px) { 
                        #statshero .Content { 
                            font-family: "Helvetica Neue", "Arial";
                            width: 90vw;
                            height: auto;
                            display: grid;
                            grid-template-columns: 1fr; 
                            grid-template-rows: 1fr;
                            align-items: center; 
                            padding: 5vw 5vw 10vw 5vw;
                            margin: 80px 0px 0px 0px; 
                        }
        
                        #statshero .LeftContent { 
                            padding: 8vw 0px 25vw 0px;
                            grid-column: 1;
                            grid-row: 1;
                            width: 70vw;
                        }
        
                        #statshero .RightContent { 
                            padding: 0px;
                            grid-column: 1;
                            grid-row: 2;
                            height: 108.84vw;
                            width: 90vw;
                            justify-self: start;
                        }
        
                        #statshero .Image { 
                            height: 108.84vw;
                            width: 90vw;
                        }
        
                        #statshero img { 
                            width: 90vw;
                            object-fit: cover;
                        }
        
        
                        #statshero .Title { 
                            font-weight: 500;
                            font-size: 5.8vw; 
                            margin: 0px;
                            padding: 0px 0px 0px 0px;
                        }
        
                        #statshero .Paragraph { 
                            font-weight: 400;
                            font-size: 2.38vw;
                            line-height: 3.56vw;
                            margin: 0px;
                            padding: 3.56vw 0px;
                        }
        
                        #statshero .Button { 
                            justify-self: center;
                            font-size: 3vw;
                            font-weight: bold;
                            padding: 3.5vw 7vw;
                            border: 0px solid rgba(255, 255, 255, 1.0);
                            border-radius: 6vw;
                            cursor: pointer; 
                            text-transform: none;
                        }
                    }
    
                `}</style>
            </div>
        )
    }
    
}   