import React from 'react';
import LargeText from "./LargeText"
import COLORS from "../../resources/colors"


const verticalLine = <div className="VerticalLineContainer">

    <div className="VerticalLine">
        <div/>
        <div className="VerticalLineLine"/>
        <div/>
    </div>
    <style jsx="true">{`
        .VerticalLine { 
            height: 100%;
            width: 8vw;
            display: grid;
            grid-template-columns: 1fr 0.178vw 1fr;
        }

        .VerticalLineLine { 
            background-color: ${COLORS.WebPurple};
        }
    `}</style>
</div>

export default function Work() { 

    return(
        <div id="statswork">
            <div className="StatsWorkContent">

                <h1 className="StatsWorkTitle">A benefit your members want:</h1>
                <div className="StatsWorkCells">
                    <div style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: COLORS.clerkieColor
                    }}/> 
                    <div className="StatsWorkCell">
                        <LargeText id="statsworkcell1" title="  " subtitle="50M" paragraph={<span>Hours a<br></br>month</span>}></LargeText>
                    </div>
                    {verticalLine}
                    <div className="StatsWorkCell">
                        <LargeText id="statsworkcell1" title="At" subtitle="50K+" paragraph="Companies"></LargeText>
                    </div>
                    {verticalLine}
                    <div className="StatsWorkCell">
                        <LargeText id="statsworkcell3" title="In" subtitle="50" paragraph="States Nationwide"></LargeText>
                    </div>
                    <div style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: COLORS.clerkieColor
                    }}/>
                </div>
            </div>
            
            <style jsx="true">{`

                #statswork .StatsWorkContent { 
                    padding: 2.97vw 0px;
                }

                #statswork .StatsWorkTitle { 
                    font-weight: 800;
                    font-size: 2.8vw;
                    width: 50vw;
                    margin: auto;
                    color: ${COLORS.WebPurple};
                    text-align: center;
                    padding: 0px 0px 0px 0px;
                }

                #statswork .StatsWorkCells { 
                    display: flex;
                    width: 100%;
                    padding: 3vw 0px 0px 0px;
                }  

                #statswork .StatsWorkCell { 
                    height: 100%;
                    align-self: flex-start;
                    padding: 1.19vw 0px;
                } 

                @media (max-width: 640px) { 
                    #statswork .StatsWorkContent { 
                        padding: 0vw 0px 5vw 0px;
                    }
    
                    #statswork .StatsWorkTitle { 
                        font-weight: 800;
                        font-size: 5.8vw;
                        margin: 0px;
                        color: ${COLORS.WebPurple};
                        text-align: left;
                        padding: 0px 0px 0px 5vw;
                    }
    
                    #statswork .StatsWorkCells { 
                        display: grid;
                        width: 100%;
                        padding: 4vw 0px 0px 0px;
                    }  
    
                    #statswork .StatsWorkCell { 
                        height: 100%;
                        align-self: flex-start;
                        padding: 0px 0px 5vw 0px;
                    } 
                }
                
            `}</style>
        </div>
    )
}   