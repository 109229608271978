import React, { useState, useEffect } from 'react'
import COLORS from '../../resources/colors'

export default function Footer(props) {
  function buttonTapped() {
    if (props.displayForm != null) {
      props.displayForm()
    }
  }

  return (
    <div id="statsfooter">
      <div className="Content">
        <h1 className="Title">Bring Clerkie to your community</h1>
        <h1 className="Paragraph">
          Give your members the best tools to get out of debt and manage their
          money.
        </h1>
        <div className="ButtonContainer">
          <button className="Button" onClick={buttonTapped}>
            Get Started
          </button>
        </div>
      </div>
      <style jsx="true">{`
        #statsfooter .Content {
          font-family: 'Helvetica Neue', 'Arial';
          width: 100%;
          background-color: ${COLORS.myClerkieBlack};
          padding: 5.95vw 0px;
        }

        #statsfooter .Title {
          width: 80vw;
          font-weight: 500;
          font-size: 3vw;
          text-align: center;
          margin: 0px auto 0px auto;
          color: ${COLORS.white};
          padding: 0px 0px 0px 0px;
        }

        #statsfooter .Paragraph {
          width: 35vw;
          font-weight: 300;
          font-size: 1.25vw;
          text-align: center;
          line-height: 2vw;
          margin: 0px auto 0px auto;
          color: ${COLORS.white};
          padding: 1.5vw 0px 1.5vw 0px;
        }

        #statsfooter .ButtonContainer {
          display: grid;
          align-items: center;
        }

        #statsfooter .Button {
          justify-self: center;
          font-size: 1.2vw;
          font-weight: bold;
          background-color: ${COLORS.funPurple};
          color: ${COLORS.white};
          padding: 1.19vw 2.9vw;
          border: 0px solid rgba(255, 255, 255, 1);
          border-radius: 2.3vw;
          cursor: pointer;
          text-transform: none;
        }

        #statsfooter button:focus {
          outline: none;
        }

        @media (max-width: 640px) {
          #statsfooter .Content {
            font-family: 'Helvetica Neue', 'Arial';
            width: 100%;
            padding: 12vw 0px;
          }

          #statsfooter .Title {
            width: 80vw;
            font-weight: 500;
            font-size: 6vw;
            text-align: center;
            margin: 0px auto 0px auto;
            padding: 0px 0px 0px 0px;
          }

          #statsfooter .Paragraph {
            width: 70vw;
            font-weight: 800;
            font-size: 2.75vw;
            text-align: center;
            line-height: 4vw;
            margin: 0px auto 0px auto;
            padding: 5vw 0px 5vw 0px;
          }

          #statsfooter .ButtonContainer {
            display: grid;
            align-items: center;
          }

          #statsfooter .Button {
            justify-self: center;
            font-size: 2.25vw;
            font-weight: bold;
            padding: 3vw 7vw;
            border: 0px solid rgba(255, 255, 255, 1);
            border-radius: 6vw;
            cursor: pointer;
            text-transform: none;
          }
        }
      `}</style>
    </div>
  )
}
