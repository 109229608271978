import React from 'react';
import COLORS from "../../resources/colors"
import './StudentDebtTitle.css'

export default class StudentDebtTitle extends React.Component { 
    
    constructor(props) { 
        super(props)

        this.id = props.id ? props.id : "statsstudentdebt"
        this.myRef = React.createRef();   

        this.className = props.class || "StudentDebtTitle"
    }

    render() { 
        return(
            <div ref={this.myRef} className={this.props.class}>
                {this.props.children}
            </div>
        )
    }  
}   