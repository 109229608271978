import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import COLORS from "../../resources/colors"
import Title from "../stats/StudentDebtTitle"

export default class Content extends React.Component { 
    
    constructor(props) { 
        super(props)

        this.id = props.id ? props.id : "loancontent"

        this.myRef = React.createRef();

        this.state = { 
            height: 0,
            visible: false, 
            titleClassName: "StudentDebtTitle",
            titleValue: null
        }  
           
        
        this.titleValues = [
            <h1>45 million borrowers<br></br>owe <span style={{fontWeight: 800}}>$1.5 trillion in<br></br>total student debt</span></h1>,
            <h1>81 million borrowers<br></br>owe <span style={{fontWeight: 800}}>$1.1 trillion in<br></br>credit card debt</span></h1>,
            <h1>8% delinquency<br></br><span style={{fontWeight: 800}}>on credit card debt</span></h1>,
            <h1>52 million paid<br></br>more than <span style={{fontWeight: 800}}>$3 Billion late fees</span></h1>
        ]
        
    }

    componentDidMount() { 

        var height = 0
        
        if (this.myRef.current != null) { 
            height = this.myRef.current.clientHeight ? this.myRef.current.clientHeight : 0
        }

        this.setState({
            height: height
        })
    }

    visibilityChanged = (visible) => { 

        if (visible === true && this.state.visible === false) { 
            this.animateValue(0)

            this.setState(
                {
                    visible: visible
                }
            )
        }   
    }

    animateValue(index) {
        
        var valueIndex = index

        if (index >= this.titleValues.length) { 
            valueIndex = 0 
        }

        this.setState({
            titleClassName: "StudentDebtTitle StudentDebtTitleFadeIn",
            titleValue: this.titleValues[valueIndex]
        })

        setTimeout(() => {
            this.setState({
                titleClassName: "StudentDebtTitle StudentDebtTitleFadeOut"
            })

            setTimeout(() => {
                this.animateValue(valueIndex + 1)
            }, 500);

        }, 2000);
    }

    render() { 
        return(
            <div ref={this.myRef} id="statsstudentdebt">
                <VisibilitySensor key="statspercentages" active={this.state.visible == false} partialVisibility={true} minTopValue={this.state.height/2} onChange={this.visibilityChanged}>
                    <div className="Content">
                        <div className={this.state.visible ? "TextContent Visible" : "TextContent"}>
                        <Title class={this.state.titleClassName}>
                            {this.state.titleValue}
                        </Title>
                        <h2 className="Paragraph">Consumer Debt has more than tripled over the past decade, crippling nearly every facet of borrowers’ lives. Many borrowers overwhelmed by student loans and credit card debt don't know their options, causing them to overpay and default on their loans. <span style={{fontWeight: 800}}>We're here to help!</span> </h2> 
                        </div> 
                    </div>
                </VisibilitySensor>
                
                <style jsx="true">{`
    
                    #statsstudentdebt .Content { 
                        font-family: "Helvetica Neue", "Arial";
                        width: 100%;
                        background-color: ${COLORS.black};
                        padding: 5.95vw 0px;
                        background-color: ${COLORS.funPurple};
                        
                    }

                    #statsstudentdebt .TextContent { 
                        opacity: 0;
                        visibility: hidden;
                    }

                    @keyframes fadeIn {
                        0% {
                          visibility: hidden;
                          opacity: 0;
                        }
                        100% {
                          visibility: visible;
                          opacity: 1;
                        }
                    }

                    @keyframes fadeOut {
                        0% {
                          visibility: visible;
                          opacity: 1;
                        }
                        100% {
                            visibility: hidden;
                            opacity: 0;
                        }
                    }

                    #statsstudentdebt .TextContent.Visible { 
                        animation-duration: 1s;
                        animation-name: fadeIn; 
                        animation-fill-mode: both;
                        animation-delay: 0.0s; 
                    }
    
                    #statsstudentdebt .Title { 
                        width: 30vw;
                        font-weight: 400;
                        font-size: 2.97vw; 
                        text-align: center;
                        margin: 0px auto 0px auto;
                        color: ${COLORS.white};
                        padding: 0px 0px 0px 0px;
                    }
    
                    #statsstudentdebt .Paragraph { 
                        width: 40vw;
                        font-weight: 400;
                        font-size: 1.19vw; 
                        line-height: 2vw;
                        text-align: center;
                        margin: 0px auto 0px auto;
                        color: ${COLORS.white};
                        padding: 1.48vw 0px 1.48vw 0px;
                    }
    
                    @media (max-width: 640px) { 
                        #statsstudentdebt .Content { 
                            font-family: "Helvetica Neue", "Arial";
                            width: 100%;
                            padding: 12vw 0px;
                        }
        
                        #statsstudentdebt .Title { 
                            width: 60vw;
                            font-weight: 400;
                            font-size: 6vw; 
                            text-align: center;
                            margin: 0px auto 0px auto;
                            padding: 0px 0px 0px 0px;
                        }
        
                        #statsstudentdebt .Paragraph { 
                            width: 60vw;
                            font-weight: 400;
                            font-size: 2.4vw; 
                            line-height: 4vw;
                            text-align: center;
                            margin: 0px auto 0px auto;
                            padding: 3vw 0px 3vw 0px;
                        }
                    }
    
                `}</style>
            </div>
        )
    }

    
}   