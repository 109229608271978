import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import COLORS from "../../resources/colors"

export default class Content extends React.Component { 
    
    constructor(props) { 
        super(props)

        this.id = props.id ? props.id : "loancontent"

        this.myRef = React.createRef();

        this.state = { 
            height: 0,
            visible: false
        }    
    }

    componentDidMount() { 

        var height = 0
        
        if (this.myRef.current != null) { 
            height = this.myRef.current.clientHeight ? this.myRef.current.clientHeight : 0
        }

        this.setState({
            height: height
        })
    }

    visibilityChanged = (visible) => { 
        this.setState(
            {
                visible: visible
            }
        )
    }

    render() { 
        return(
            <div ref={this.myRef} id="statsstudy">
                <VisibilitySensor key="statsstudy" active={this.state.visible == false} partialVisibility={true} minTopValue={this.state.height/2} onChange={this.visibilityChanged} >
                    <div className="Content">
                    <h1 className={this.state.visible ? "Title Visible" : "Title"}>According to <span style={{color: COLORS.funPurple}}>Metlife</span>, 1 out of every 3 employees admit to being less productive at work because of financial stress</h1>
                    </div>
                </VisibilitySensor>
                
                <style jsx="true">{`
    
                    #statsstudy .Content { 
                        font-family: "Helvetica Neue", "Arial";
                        width: 100%;
                        background-color: ${COLORS.black};
                    }
                    #statsstudy .Title { 
                        justify-self: center;
                        width: 65vw;
                        font-weight: 800;
                        font-size: 3.6vw; 
                        text-align: center;
                        text-transform: uppercase;
                        margin: 0px auto 0px auto;
                        color: ${COLORS.white};
                        padding: 10.4vw 0px 10.4vw 0px;
                        opacity: 0;
                    }

                    @keyframes fadeIn {
                        0% {
                          visibility: hidden;
                          opacity: 0;
                        }
                        100% {
                          visibility: visible;
                          opacity: 1;
                        }
                    }

                    #statsstudy .Title.Visible { 
                        animation-duration: 1s;
                        animation-name: fadeIn; 
                        animation-fill-mode: both;
                        animation-delay: 0.0s; 
                    }
    
                    @media (max-width: 640px) { 
                        #statsstudy .Content { 
                            font-family: "Helvetica Neue", "Arial";
                            width: 100%;
                        }
        
                        #statsstudy .Title { 
                            justify-self: center;
                            width: 80vw;
                            font-weight: 800;
                            font-size: 7.2vw; 
                            text-align: center;
                            text-transform: uppercase;
                            margin: 0px auto 0px auto;
                            padding: 20vw 0px 20vw 0px;
                        }
                    }
    
                `}</style>
            </div>
        )
    }

    
}   