import React, { useState, useEffect } from 'react'
import Hero from "../components/stats/Hero"
import InfoCells from "../components/stats/InfoCells"
import Reduce from "../components/stats/Reduce"
import Work from "../components/stats/Work"
import Study from "../components/stats/Study"
import Percentages from "../components/stats/Percentages"
import Good from "../components/stats/Good"
import StudentDebt from "../components/stats/StudentDebt"
import PayingDown from "../components/stats/PayingDown"
import Save from "../components/stats/Save"
import Images from "../components/stats/Images"
import Footer from "../components/stats/Footer"
import StatsForm from "../components/stats/StatsForm"
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import COLORS from "../resources/colors"
import Layout from "../components/layout"
import { navigate } from "gatsby"
import PurpleLogo from "../images/logo-fun-purple.svg"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export default function Companies() {

  const [formDisplayed, setFormDisplayed] = useState(false)
  const [didMount, setDidMount] = useState(false)

  useEffect(() => {

    if (didMount === false) {
      window.addEventListener('scroll', handleScroll);
      setDidMount(true)
    }
  }, [didMount]);

  function hideForm() {
    setFormDisplayed(false)
  }

  function displayForm() {

    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Button",
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "Learn More"
    })
    setFormDisplayed(true)
  }

  function handleScroll(event) {

  }



  return (

    <ParallaxProvider>
      <Layout primaryLogo={PurpleLogo} primaryColor={COLORS.funPurple} alwaysPrimary={true} disableScrollAnimation={true} headerScrolledColor={"black"} secondaryButtonPrimaryBackground={"#333"} secondaryButtonPrimaryColor={"white"} addImageMargin={false} heroMobileMultiplier={0.54} heroDesktopMultiplier={2.0} buttons={[
        {
          title: "Learn More",
          action: displayForm
        },
        {
          title: "Request Demo",
          action: displayForm,
          showOnMobile: true,
          isSecondary: true
        },
        {
          title: "Members",
          action: function () {
            navigate("/members")
            trackCustomEvent({
              // string - required - The object that was interacted with (e.g.video)
              category: "Button",
              // string - required - Type of interaction (e.g. 'play')
              action: "Click",
              // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
              label: "Members"
            })
          }
        },
        {
          title: "Companies",
          action: function () {
            navigate("/companies")
            trackCustomEvent({
              // string - required - The object that was interacted with (e.g.video)
              category: "Button",
              // string - required - Type of interaction (e.g. 'play')
              action: "Click",
              // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
              label: "Companies"
            })
          },
          selected: true
        },
        {
          title: "Lenders",
          action: function () {
            navigate("/")
            trackCustomEvent({
              // string - required - The object that was interacted with (e.g.video)
              category: "Button",
              // string - required - Type of interaction (e.g. 'play')
              action: "Click",
              // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
              label: "Lenders"
            })
          },
          showOnMobile: false
        },

      ]} menuButtons={
        [
          {
            title: "Lenders",
            type: "item",
            action: function () {
              navigate("/")
              trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "Button",
                // string - required - Type of interaction (e.g. 'play')
                action: "Click",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "Lenders"
              })
            }
          },
          {
            title: "Companies",
            type: "item",
            action: function () {
              navigate("/companies")
              trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "Button",
                // string - required - Type of interaction (e.g. 'play')
                action: "Click",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "Companies"
              })
            },
            selected: true
          },
          {
            title: "Members",
            type: "item",
            action: function () {
              navigate("/members")
              trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "Button",
                // string - required - Type of interaction (e.g. 'play')
                action: "Click",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "Members"
              })
            }
          },
        ]
      }>
        <div className="CompaniesRoot">

          <div className={formDisplayed ? "CompaniesContent Disabled" : "CompaniesContent Active"}>
            <Hero displayForm={displayForm} />
            <Images />
            <InfoCells />
            <Reduce />
            {/* <Work/> */}
            <Study />
            <Percentages />
            <Good displayForm={displayForm} />
            <StudentDebt />
            <PayingDown displayForm={displayForm} />
            {/* <Save/>  */}
            <Footer displayForm={displayForm} />
          </div>

          <div className={formDisplayed ? "FormBackground Visible" : "FormBackground Hidden"}>
            <StatsForm title="Give your employees a benefit they love!" hideForm={hideForm} name="companies-form" />
          </div>

          <style global jsx="true">{`

                body { 
                    font-family: "Helvetica Neue", "Arial";   
                    margin: 0px;
                    padding: 0px;
                    overflow: ${formDisplayed ? "hidden" : "auto"};
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    background-color: black;
                }

            `}</style>


          <style jsx="true">{`

            .CompaniesRoot { 
              display: grid;
              align-items: start;
            }

            .CompaniesContent { 
              grid-row: 1;
              grid-column: 1;
              overflow: hidden;
              z-index: 10;
            }

            .CompaniesContent.Active { 
              pointer-events: auto;
            }

            .CompaniesContent.Disabled { 
              pointer-events: none;
            }

            .ShapesBackground { 
              grid-row: 1;
              grid-column: 1;
              height: 100%;
              width: 100%;
              display: grid;
              grid-template-columns: 1fr 20vw 20vw 20vw 20vw 1fr;
              grid-template-rows: 45vw 70vw;
              row-gap: 100vh;
              padding-top: 0;
            }

            img { 
              width: 100%;
            }
          
            .Shape1 { 
              grid-row: 1;
              grid-column: 4 / span 2;
              width: 100%; 
            }

            .Shape2 { 
              grid-row: 2;
              grid-column: 2 / span 2;
              width: 100%; 
            }

            .FormBackground { 
              grid-row: 1;
              grid-column: 1;
              height: 100%;
              width: 100%;
              z-index: 100;
              transition: 0.15s; 
              opacity: 0;
            }

            .FormBackground.Hidden { 
              opacity: 0;
              pointer-events: none;
            }

            .FormBackground.Visible { 
              opacity: 100;
              pointer-events: auto;
            }
          
      `}</style>
        </div>
      </Layout>

    </ParallaxProvider>
  )
} 
