import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Parallax } from 'react-scroll-parallax';
import COLORS from "../../resources/colors"

export default class Content extends React.Component { 
    
    constructor(props) { 
        super(props)

        this.id = props.id ? props.id : "loancontent"

        this.myRef = React.createRef();

        this.state = { 
            height: 0,
            visible: false,
            percent: 0,
            isMobile: false
        }    
    }

    componentDidMount() { 

        var height = 0
        
        if (this.myRef.current != null) { 
            height = this.myRef.current.clientHeight ? this.myRef.current.clientHeight : 0
        }

        this.setState({
            height: height
        })

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    handleResize = () => {

        const innerWidth = window.innerWidth;
        const isMobile = window.innerWidth <= 640;
        
        if (this.state.isMobile != isMobile) { 
            this.setState({ isMobile: isMobile } );
        }
      };

    visibilityChanged = (visible) => { 

        if (visible) { 
            this.animateValue1(0)
            this.setState(
                {
                    visible: visible
                }
            )
        }  
    }

    animateValue1(number) {
        setTimeout(() => {
            if (number < 75) { 

                this.setState(
                    {
                        percent: number + 1
                    }
                )
                this.animateValue1(number + 1)
            } 
        }, 20);
    }

    render() {
        return(
            <div id="statsreduce">
                <div className="Content">
                    <div className="LeftContent">
                        <Parallax  y={[10, -5]} styleOuter={
                            this.state.isMobile ? {
                                height: "98.43vw",
                                width: "90vw",
                                gridRow: 1, 
                                gridColumn: 1,
                                overflow: "hidden",
                                zIndex: 1
                            } : {
                                height: "35vw",
                                width: "32vw",
                                gridRow: 1, 
                                gridColumn: 1,
                                overflow: "hidden",
                                zIndex: 1
                            }   
                        } >
                            <img src={require("../../images/jumping.jpg")}></img>   
                        </Parallax>
                        <Parallax y={[-20, 10]} x={[-15, -15]} styleOuter={
                        this.state.isMobile ? {
                            gridRow: 1, 
                            gridColumn: 1,
                            zIndex: 0,
                            transform: "scale(1.15, 1.15)",
                            marginTop: "20vw"
                        } : 
                        {
                            gridRow: 1, 
                            gridColumn: 1,
                            zIndex: 0,
                            transform: "scale(1.25, 1.25)",
                            marginTop: "5vw"
                        }
                    }>
                        <img src={require("../../images/parallelogram-web-light-blue.png")}></img>         
                    </Parallax>
                    </div>
                    
                    <VisibilitySensor key="statsreduce" active={this.state.visible == false} partialVisibility={true} minTopValue={this.state.height/2} onChange={this.visibilityChanged} >
                        <div ref={this.myRef} className={this.state.visible ? "RightContent Visible" : "RightContent"}>
                            <h1 className="Title">{this.state.percent}%</h1>
                            <h1 className="Paragraph">say Clerkie has helped reduce<br></br> or pay off debt</h1>  
                        </div>
                    </VisibilitySensor>
                </div>
                <style jsx="true">{`
    
                    #statsreduce .Content { 
                        font-family: "Helvetica Neue", "Arial";
                        width: 100%;
                        height: 55.7vw;
                        display: grid;
                        grid-template-columns: 10vw 32vw 8vw 40vw 10vw; 
                        grid-template-rows: 1fr;
                        align-items: center; 
                        padding: 5vw 0px;
                    }
    
                    #statsreduce .LeftContent { 
                        padding: 0px;
                        grid-column: 2;
                        grid-row: 1;
                        height: 35vw;
                        display: grid;
                    }
    
                    #statsreduce .RightContent { 
                        width: 100%;
                        height: auto;
                        grid-row: 1;
                        grid-column: 4;
                        padding: 0px;
                        margin: 0px;
                        visibility: hidden;
                    }

                    #statsreduce .RightContent.Visible { 
                        animation-duration: 0.75s;
                        animation-name: enterRightToLeft; 
                        animation-fill-mode: both;
                        animation-delay: 0.0s;  
                    }

                    @keyframes enterRightToLeft {
                        0% {
                          transform: translate(100px, 0);
                          visibility: hidden;
                          opacity: 0;
                        }
                        100% {
                          transform: translate(0, 0);
                          visibility: visible;
                          opacity: 1;
                        }
                      }
    
                    #statsreduce .Image { 
                        height: 100%;
                    }
    
                    #statsreduce img { 
                        width: 100%;
                        object-fit: cover;
                    }
    
                    #statsreduce .Title { 
                        font-weight: 800;
                        font-size: 8.92vw; 
                        margin: 0px;
                        color: ${COLORS.funPurple};
                        padding: 0px 0px 0px 0px;
                    }
    
                    #statsreduce .Paragraph { 
                        font-weight: 600;
                        font-size: 2.38vw;
                        margin: 0px;
                        padding: 1.79vw 0px 0px 0px;
                        color: ${COLORS.funPurple};
                    }
    
                    @media (max-width: 640px) { 
                        #statsreduce .Content { 
                            font-family: "Helvetica Neue", "Arial";
                            width: 90vw;
                            height: auto;
                            display: grid;
                            grid-template-columns: 1fr; 
                            grid-template-rows: 1fr;
                            align-items: center; 
                            padding: 5vw;
                        }
        
                        #statsreduce .LeftContent { 
                            padding: 5vw 0px;
                            grid-column: 1;
                            grid-row: 1;
                            height: 108.84vw;
                            width: 90vw;
                        }
        
                        #statsreduce .RightContent { 
                            width: 100%;
                            height: auto;
                            grid-row: 2;
                            grid-column: 1;
                            padding: 0px;
                            margin: 0px;
                        }
        
                        #statsreduce .Image { 
                            height: 100%;
                        }
        
                        #statsreduce img { 
                            width: 100%;
                            object-fit: cover;
                        }
        
                        #statsreduce .Title { 
                            font-weight: 800;
                            font-size: 17.84vw; 
                            margin: 0px;
                            padding: 5vw 0px 0px 0px;
                        }
        
                        #statsreduce .Paragraph { 
                            font-weight: 600;
                            font-size: 4.76vw;
                            margin: 0px;
                            padding: 3.58vw 0px 15vw 0px;
                        }
                    }
    
                `}</style>
            </div>
        )
    }

    
}   