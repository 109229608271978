import React, { useState, useEffect } from 'react';
import PercentCell from "./PercentCell"
import VisibilitySensor from 'react-visibility-sensor';
import COLORS from "../../resources/colors"

export default class Content extends React.Component { 
    
    constructor(props) { 
        super(props)

        this.id = props.id ? props.id : "loancontent"

        this.myRef = React.createRef();

        this.state = { 
            height: 0,
            visible: false,
            percentValue1: 0,
            percentValue2: 0,
            percentValue3: 0
        }    
    } 

    componentDidMount() { 

        var height = 0
        
        if (this.myRef.current != null) { 
            height = this.myRef.current.clientHeight ? this.myRef.current.clientHeight : 0
        }
        
        this.setState({
            height: height
        })
    }

    visibilityChanged = (visible) => { 

        if (visible) { 
            this.animateValue1(0)
            this.setState(
                {
                    visible: visible
                }
            )
        }  
    }

    animateValue1(number) {
        setTimeout(() => {
            if (number < 91) { 
                this.setState(
                    {
                        percentValue1: number + 1
                    }
                )
                this.animateValue1(number + 1)

                if (number === 25) { 
                    this.animateValue2(0)
                }
            } 
        }, 10);
    }

    animateValue2(number) {
        setTimeout(() => {
            if (number < 87) { 
                this.setState(
                    {
                        percentValue2: number + 1
                    }
                )
                this.animateValue2(number + 1)

                if (number === 25) { 
                    this.animateValue3(0)
                }
            } 
        }, 10);
    }

    animateValue3(number) {
        setTimeout(() => {
            if (number < 73) { 
                this.setState(
                    {
                        percentValue3: number + 1
                    }
                )
                this.animateValue3(number + 1)
            }
        }, 10);
    }

    render() { 
        return(
            <div ref={this.myRef} id="statspercentages">
                <VisibilitySensor key="statspercentages" active={this.state.visible == false} partialVisibility={true} minTopValue={this.state.height/2} onChange={this.visibilityChanged}>
                    <div className="StatsPercentagesContent">
                        <h1 className="Title">Provide a benefit with <span style={{fontWeight: 800}}>true impact</span></h1>
                        <div className="StatsPercentagesCells"> 
                            <div className="StatsPercentagesCell1">
                                <PercentCell isVisible={this.state.percentValue1 > 0} id="percentagecell1" title={this.state.percentValue1 + "%"} paragraph="more motivated at work knowing they have this benefit"></PercentCell>
                            </div>
                            <div className="StatsPercentagesCell2">
                                <PercentCell isVisible={this.state.percentValue2 > 0} id="percentagecell2" title={this.state.percentValue2 + "%"} paragraph="get out of debt faster and feel more in control with their money"></PercentCell>
                            </div>
                            <div className="StatsPercentagesCell3">
                                <PercentCell isVisible={this.state.percentValue3 > 0} id="percentagecell3" title={this.state.percentValue3 + "%"} paragraph="freed room in their budget and paid their bills on time"></PercentCell>
                            </div>
                        </div>
                    </div>
                </VisibilitySensor>
                
                <style jsx="true">{`
    
                    #statspercentages .StatsPercentagesContent { 
                        padding: 3vw 0px;
                        background-color: ${COLORS.funPurple}
                    }
    
                    #statspercentages .StatsPercentagesCells { 
                        display: grid;
                        width: 100%;
                        grid-template-columns: 1fr 18vw 18vw 18vw 1fr;
                        column-gap: 4vw;
                        align-items: start;
                        padding: 2.67vw 0px 2.67vw 0px;
                    }  
    
                    #statspercentages .Title { 
                        font-weight: 500;
                        font-size: 2.08vw;
                        width: 100%; 
                        margin: 0px;
                        color: ${COLORS.white};
                        text-align: center;
                        padding: 0px 0px;
                    }

                    #statspercentages .StatsPercentagesCell1 { 
                        grid-column: 2;
                        grid-row: 1;
                        height: 100%;
                        width: 100%;
                    } 
    
                    #statspercentages .StatsPercentagesCell2 { 
                        grid-column: 3;
                        grid-row: 1;
                        height: 100%;
                        width: 100%;
                    } 
    
                    #statspercentages .StatsPercentagesCell3 { 
                        grid-column: 4;
                        grid-row: 1;
                        height: 100%;
                        width: 100%;
                    } 
    
                    @media (max-width: 640px) { 
                        #statspercentages .StatsPercentagesContent { 
                            padding: 0px 0px;
                        }

                        #statspercentages .Title { 
                            font-weight: 500;
                            width: 70%; 
                            text-align: center;
                            font-size: 6vw; 
                            margin: auto;
                            padding: 15vw 0px 0px 0px;
                        }
        
                        #statspercentages .StatsPercentagesCells { 
                            display: grid;
                            width: 100%;
                            grid-template-columns: 1fr;
                            column-gap: 0vw;
                            align-items: center;
                            padding: 10vw 0px 15vw 0px;
                        }  
        
                        #statspercentages .StatsPercentagesCell1 { 
                            grid-column: 1;
                            grid-row: 1;
                            height: 100%;
                            width: 100%;
                        } 
        
                        #statspercentages .StatsPercentagesCell2 { 
                            grid-column: 1;
                            grid-row: 2;
                            height: 100%;
                            width: 100%;
                        } 
        
                        #statspercentages .StatsPercentagesCell3 { 
                            grid-column: 1;
                            grid-row: 3;
                            height: 100%;
                            width: 100%;
                        }
                    }
                `}</style>
            </div>
        )
    }
    
}   