import React from 'react';
import COLORS from "../../resources/colors"

export default function PercetCell(props) { 

    let id = props.id ? props.id : "percentcell"
    let isVisible = props.isVisible ? props.isVisible : false

    return(
        <div id={id}>
            <div className={isVisible === true ? "Content is-visible" : "Content"}>
            <h1 className="Title">{props.title}</h1>
            <h1 className="Paragraph">{props.paragraph}</h1>
                
            </div>
            <style jsx="true">{`

                #${id} .Content { 
                    font-family: "Helvetica Neue", "Arial";
                    width: 100%;  
                    opacity: 0;
                    transition: 0.5s;  
                    visibility: hidden; 
                }

                @keyframes fadeIn {
                    0% {
                      visibility: hidden;
                      opacity: 0;
                      transform: translate(0px, 50px); 
                    }
                    100% {
                      visibility: visible;
                      opacity: 1;
                      transform: translate(0, 0);  
                    }
                }

                #${id} .Content.is-visible { 
                    animation-duration: 1s;
                    animation-name: fadeIn; 
                    animation-fill-mode: both;
                }


                #${id} .Title { 
                    font-weight: 600;
                    font-size: 4vw; 
                    text-align: center;
                    margin: 0px;
                    color: ${COLORS.white};
                    padding: 0.595vw 0px 0px 0px;  
                }

                #${id} .Paragraph { 
                    font-weight: 400;
                    font-size: 1vw;
                    text-align: center;
                    line-height: 1.7vw;
                    margin: 0px;
                    padding: 0.89vw 0px 0px 0px;
                    color: ${COLORS.white};
                }

                @media (max-width: 640px) { 
                    #${id} .Content { 
                        font-family: "Helvetica Neue", "Arial";
                        width: 100%; 
                        padding-top: 5vw;  
                    }
    
                    #${id} .Title { 
                        font-weight: 600;
                        font-size: 12vw; 
                        text-align: center;
                        margin: 0px;
                        color: ${COLORS.white};
                        padding: 0.595vw 0px 0px 0px;  
                    }
    
                    #${id} .Paragraph { 
                        font-weight: 400;
                        font-size: 3vw;
                        text-align: center;
                        line-height: 6vw;
                        width: 40vw;
                        margin: 0px auto 0px auto;
                        padding: 2vw 0px 0px 0px;
                        color: ${COLORS.white};
                    }
                }
            `}</style>
        </div>
    )
}   