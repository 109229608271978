import React, { useState, useEffect } from 'react';
import InfoCell from "./InfoCell"
import VisibilitySensor from "react-visibility-sensor"
import COLORS from "../../resources/colors"
import CashIcon from "../../images/card-purple.png"
import ProfileIcon from "../../images/chat-purple.png"
import ContractIcon from "../../images/doc-purple.png"

export default class InfoCells extends React.Component { 
    
    constructor(props) { 
        super(props)

        this.myRef = React.createRef();

        this.state = { 
            active: false,
            height: 0,
            visible: false,
            cell1Visible: false,
            cell2Visible: false,
            cell3Visible: false
        }    
    }

    componentDidMount() { 

        var height = 0
        
        if (this.myRef.current != null) { 
            height = this.myRef.current.clientHeight ? this.myRef.current.clientHeight : 0
        }

        this.setState({
            height: height, 
            active: true
        })
    }

    visibilityChanged = (visible) => { 

        if (visible) { 

            this.animateCell1()
            this.setState(
                {
                    visible: visible
                }
            )
        }    
    }

    animateCell1() { 

        this.setState(
            {
                cell1Visible: true
            }
        )

        setTimeout(() => {
            this.animateCell2()
        }, 400);
    }

    animateCell2() { 

        this.setState(
            {
                cell2Visible: true
            }
        )

        setTimeout(() => {
            this.animateCell3()
        }, 400);
    }

    animateCell3() { 

        this.setState(
            {
                cell3Visible: true
            }
        )
    }

    render() { 
        return(
            <div ref={this.myRef} id="infocells">
                <VisibilitySensor key="infocells" active={this.state.active} partialVisibility={true} minTopValue={this.state.height/2} onChange={this.visibilityChanged}>
                    <div className="InfoCellsContent">
                        <h1 className="InfoCellsTitle">A simple and powerful solution <span style={{fontWeight: 800, color: COLORS.funPurple}}>for all debt</span></h1>
                        <div className="InfoCellsCells"> 
                            <div className={this.state.cell1Visible ? "InfoCell1 Visible" : "InfoCell1"}>
                                <InfoCell id="infocell1" imageUrl={ProfileIcon} title="Financial Assistant" paragraph="Every member gets access to a dedicated Financial Assistant who understands and inspires beyond a standalone app."></InfoCell>
                            </div>
                            <div className={this.state.cell2Visible ? "InfoCell2 Visible" : "InfoCell2"}>
                                <InfoCell id="infocell2" imageUrl={ContractIcon} title="Instant Debt Reductions" paragraph="Our automated platform gives members access to instant debt negotiation savings for their credit cards and student loans"></InfoCell>
                            </div>
                            <div className={this.state.cell3Visible ? "InfoCell3 Visible" : "InfoCell3"}>
                                <InfoCell id="infocell3" imageUrl={CashIcon} title="Access Emergency Cash" paragraph="As members, you get access to responsible solutions to address your urgent money needs and avoid payday loans"></InfoCell>
                            </div>
                        </div>
                    </div>    
                </VisibilitySensor>
                
                <style jsx="true">{`
                    #infocells .InfoCellsContent { 
                        padding: 4vw 0px;
                        background-color: ${COLORS.black}; 
                    }
    
                    #infocells .InfoCellsTitle { 
                        font-weight: 500;
                        font-size: 2.08vw;
                        margin: 0px;
                        color: ${COLORS.white};
                        text-align: center;
                        padding: 0 0 1vw 0;
                    }
    
                    #infocells .InfoCellsCells { 
                        display: grid;
                        width: 100%;
                        grid-template-columns: 1fr 20vw 20vw 20vw 1fr;
                        column-gap: 4vw;
                        align-items: center;
                        padding: 2.98vw 0px 0px 0px;
                    }  

                    @keyframes fadeIn {
                        0% {
                          visibility: hidden;
                          opacity: 0;
                        }
                        100% {
                          visibility: visible;
                          opacity: 1;
                        }
                    }

                    #infocells .Visible { 
                        animation-duration: 1s;
                        animation-name: fadeIn; 
                        animation-fill-mode: both;
                        animation-delay: 0.0s; 
                    }
    
                    #infocells .InfoCell1 { 
                        grid-column: 2;
                        grid-row: 1;
                        height: 100%;
                        width: 100%;
                        visibility: hidden;
                          opacity: 0;
                    } 
    
                    #infocells .InfoCell2 { 
                        grid-column: 3;
                        grid-row: 1;
                        height: 100%;
                        width: 100%;
                        visibility: hidden;
                        opacity: 0;
                    } 
    
                    #infocells .InfoCell3 { 
                        grid-column: 4;
                        grid-row: 1;
                        height: 100%;
                        width: 100%;
                        visibility: hidden;
                        opacity: 0;
                    } 
    
                    @media (max-width: 640px) { 
                        #infocells .InfoCellsContent { 
                            padding: 20vw 0px;
                        }
        
                        #infocells .InfoCellsTitle { 
                            font-weight: 500;
                            font-size: 5.8vw;
                            width: 60vw; 
                            margin: auto;
                            text-align: center;
                            padding: 0px 20vw 5vw 20vw;
                        }
        
                        #infocells .InfoCellsCells { 
                            display: grid;
                            width: 100%;
                            grid-template-columns: 1fr;
                            column-gap: 0vw;
                            row-gap: 10vw;
                            align-items: center;
                            padding: 5.96vw 0px 0px 0px;
                        }  
        
                        #infocells .InfoCell1 { 
                            grid-column: 1;
                            grid-row: 1;
                            height: 100%;
                            width: 100%;
                        } 
        
                        #infocells .InfoCell2 { 
                            grid-column: 1;
                            grid-row: 2;
                            height: 100%;
                            width: 100%;
                        } 
        
                        #infocells .InfoCell3 { 
                            grid-column: 1;
                            grid-row: 3;
                            height: 100%;
                            width: 100%;
                        } 
                    }
                `}</style>
            </div>
        )
    }

    
}   