import React, { useState, useEffect } from 'react';
import LargeText from "./LargeText"
import COLORS from "../../resources/colors"


const verticalLine = <div className="VerticalLineContainer">

    <div className="VerticalLine">
        <div/>
        <div className="VerticalLineLine"/>
        <div/>
    </div>
    <style jsx="true">{`
        .VerticalLine { 
            height: 100%;
            width: 40px;
            display: grid;
            grid-template-columns: 1fr 3px 1fr;
        }

        .VerticalLineLine { 
            background-color: ${COLORS.WebPurple};
        }
    `}</style>
</div>

export default function Images() { 

    return(
        <div id="statsimages">
            <div className="Content">
                <div className="Cells">
                    
                    <div className="ImageCell1">
                        <img src={require('../../images/yahoo.png')}></img>
                    </div>
                    <div className="ImageCell2">
                        <img src={require('../../images/bloomberg.png')}></img> 
                    </div>
                    <div className="ImageCell3">
                        <img src={require('../../images/wall-street-journal.png')}></img>
                    </div>
                    <div className="ImageCell4">
                        <img src={require('../../images/medium.png')}></img>
                    </div>

                </div>
            </div>
            
            <style jsx="true">{`

                #statsimages .Content { 
                    padding: 0px;
                    background-color: ${COLORS.webLightBlue};
                }

                #statsimages .Cells { 
                    display: flex;
                    width: 65vw;
                    height: 5vw;
                    margin: auto;
                    justify-content: space-between;
                    padding: 4vw 0px;   
                }  

                #statsimages .ImageContainer { 
                    justify-self: center;
                    background-color: red;
                    
                } 

                #statsimages .ImageCell1 { 
                    width: 12vw;
                    align-self: flex-start;
                    display: grid;
                    align-items: center;
                    height: 100%;
                } 

                #statsimages .ImageCell2 { 
                    width: 12vw;
                    align-self: flex-start;
                    display: grid;
                    align-items: center;
                    height: 100%;
                } 

                #statsimages .ImageCell3 { 
                    width: 12vw;
                    align-self: flex-start;
                    display: grid;
                    align-items: center;
                    height: 100%;
                } 

                #statsimages .ImageCell4 { 
                    width: 12vw;
                    align-self: flex-start;
                    display: grid;
                    align-items: center;
                    height: 100%;
                } 

                #statsimages img {
                    width: 12vw;
                    display:block;
                }

                @media (max-width: 640px) { 
                    #statsimages .Content { 
                        padding: 0px;
                    }
    
                    #statsimages .Cells { 
                        display: grid;
                        width: 40vw;
                        height: auto;
                        margin: auto;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-template-rows: 1fr;
                        padding: 8vw 30vw;
                        align-items: center;
                        row-gap: 3vw;
                    }  
    
                    #statsimages .ImageCell1 { 
                        width: 40vw;
                        grid-column: 1;
                        grid-row: 1;
                    } 
    
                    #statsimages .ImageCell2 { 
                        width: 40vw;
                        grid-column: 1;
                        grid-row: 2;
                    } 
    
                    #statsimages .ImageCell3 { 
                        width: 40vw;
                        grid-column: 1;
                        grid-row: 3;
                    } 
    
                    #statsimages .ImageCell4 { 
                        width: 40vw;
                        grid-column: 1;
                        grid-row: 4;
                    }  
    
                    #statsimages img {
                        width: 40vw;
                        display:block;
                    }
                }

                
            `}</style>
        </div>
    )
}   