import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import COLORS from "../../resources/colors"

export default class Content extends React.Component { 
    
    constructor(props) { 
        super(props)

        this.myRef = React.createRef();

        this.state = { 
            height: 0,
            visible: false
        }    
    }

    componentDidMount() { 

        var height = 0
        
        if (this.myRef.current != null) { 
            height = this.myRef.current.clientHeight ? this.myRef.current.clientHeight : 0
        }

        this.setState({
            height: height
        })
    }

    visibilityChanged = (visible) => { 
        this.setState(
            {
                visible: visible
            }
        )
    }

    render() { 
        return(
            <div id="statssave">
                <div className="Content">
                    <div ref={this.myRef} className="LeftContent">
                        <VisibilitySensor key="statssave" active={this.state.visible == false} partialVisibility={true} minTopValue={this.state.height/2} onChange={this.visibilityChanged} >
                            <h1 className={this.state.visible ? "Title Visible" : "Title"}>Borrowers who enroll in<br></br>income-driven repayment<br></br>plans <span style={{fontWeight: 800}}>save an average of<br></br>$2,232 a year</span></h1>
                        </VisibilitySensor>    
                    </div>
                    <div className="RightContent">
                       <h1 className="ListHeader">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
                       <ul className="List">
                            <li className="ListItem">Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                            <li className="ListItem">Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                            <li className="ListItem">Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                       </ul>
                       
                    </div>
                </div>
                <style jsx="true">{`
    
                    #statssave .Content { 
                        font-family: "Helvetica Neue", "Arial";
                        width: 100%;
                        display: grid;
                        grid-template-columns: 12.5vw 1fr 1fr 12.5vw; 
                        grid-template-rows: 1fr;
                        align-items: start; 
                        padding: 4vw 0px;
                        background-color: ${COLORS.webLightBlue};
                    }
    
                    #statssave .LeftContent { 
                        padding: 0px;
                        grid-column: 2;
                        grid-row: 1;
                    }
    
                    #statssave .RightContent { 
                        width: 100%;
                        grid-row: 1;
                        grid-column: 3;
                        padding: 0px;
                        margin: 0px;
                    }
    
                    #statssave .Title { 
                        font-weight: 400;
                        font-size: 2.38vw; 
                        margin: 0px;
                        color: ${COLORS.myClerkieBlack};
                        padding: 0px 0px 0px 0px;    
                        opacity: 0;
                    }

                    @keyframes fadeIn {
                        0% {
                          visibility: hidden;
                          opacity: 0;
                        }
                        100% {
                          visibility: visible;
                          opacity: 1;
                        }
                    }

                    #statssave .Title.Visible { 
                        animation-duration: 1s;
                        animation-name: fadeIn; 
                        animation-fill-mode: both;
                        animation-delay: 0.0s; 
                    }
    
                    #statssave .ListHeader { 
                        width: 100%;
                        font-weight: 600;
                        font-size: 1.19vw;
                        margin: 0px;
                        padding: 0px 0px 0.29vw 0px;
                        color: ${COLORS.myClerkieBlack};
                    }
    
                    #statssave .List { 
                        padding: 0px 0px 0px 3.57vw;
                    }
    
                    #statssave .ListItem { 
                        font-weight: 400;
                        font-size: 1.19vw;
                        margin: 0px;
                        padding: 0.41vw 0px 0.41vw  0px;
                        color: ${COLORS.myClerkieBlack};
                    }
    
                    @media (max-width: 640px) { 
                        #statssave .Content { 
                            font-family: "Helvetica Neue", "Arial";
                            width: 90vw;
                            display: grid;
                            grid-template-columns: 1fr; 
                            grid-template-rows: 1fr;
                            align-items: start; 
                            padding: 8vw 5vw;
                            background-color: ${COLORS.webLightBlue};
                        }
        
                        #statssave .LeftContent { 
                            padding: 0px;
                            grid-column: 1;
                            grid-row: 1;
                        }
        
                        #statssave .RightContent { 
                            width: 100%;
                            grid-row: 2;
                            grid-column: 1;
                            padding: 10vw 0px 0px 0px;
                            margin: 0px;
                            
                        }
        
                        #statssave .Title { 
                            font-weight: 400;
                            font-size: 4.76vw; 
                            margin: 0px;
                            color: ${COLORS.myClerkieBlack};
                            padding: 0px 0px 0px 0px;    
                        }
        
                        #statssave .ListHeader { 
                            width: 100%;
                            font-weight: 600;
                            font-size: 2.4vw;
                            margin: 0px;
                            padding: 0px 0px 0.6vw 0px;
                            color: ${COLORS.myClerkieBlack};
                        }
        
                        #statssave .List { 
                            padding: 0px 0px 0px 7vw;
                        }
        
                        #statssave .ListItem { 
                            font-weight: 400;
                            font-size: 2.4vw;
                            margin: 0px;
                            padding: 0.8vw 0px 0.81vw  0px;
                            color: ${COLORS.myClerkieBlack};
                        }
                    }
    
                `}</style>
            </div>
        )
    } 
}   